import crList, {crCollection} from '@/constants/crList';
import DEFAULT_FILTER_VALUE from '@/constants/DEFAULT_FILTER_VALUE';
import sizeList from '@/constants/sizeList';
import aligmentList from '@/constants/aligmentList';
import languageList from '@/constants/languageList';
import creatureTypeList from '@/constants/creatureTypeList';
import damageTypeList from '@/constants/damageTypeList';
import conditionList from '@/constants/conditionList';
import creatureAbilityList from '@/constants/creatureAbilityList';
import SEARCH_PROP_NAME from '@/constants/SEARCH_PROP_NAME';
import { GENDER_MALE, GENDER_ANY } from '@/constants/genderList';
import {
  FILTER_TYPE_INPUT,
  FILTER_TYPE_SELECT,
} from '@/constants/filterTypes'

import upLetter from '@/utils/upLetter';
import sortByText from '@/utils/sortByText';

const spoilerWarning = `Текущие фильтры будут показывать в том числе НИП, играющих важную роль в сюжете приключений или лора D&D. Ссылки на них обведены рамочкой.

Информация из их описаний и статблоков может содержать спойлеры и, поэтому, предназначена только для Мастеров. Если Вы не планируете водить приключения с их участием, мы настоятельно рекомендуем Вам воздержаться от просмотра такой информации.

Не портите себе впечатление от будущих игр.`

export default [
  {
    header: 'Основное',
    children: [
      {
        label: 'Размер',
        type: FILTER_TYPE_SELECT,
        propName: 'sizeType',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любой'
          },
          ...sizeList
            .slice(1)
            .map(
              ({ name: { singular }, id: value }) => ({
                value,
                text: upLetter(singular[GENDER_MALE].nominative),
              })
            )
        ]
      },
      {
        label: 'Тип',
        type: FILTER_TYPE_SELECT,
        propName: 'creatureTypeIdList',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любой'
          },
          ...creatureTypeList
            .filter(
              ({ hideFromFilter }) => !hideFromFilter
            )
            .map(
              ({ name: {plural}, id: value }) => {
                const {nominative: str} = plural
                return ({ value, text: upLetter(str) })
              }
            )
            .sort(sortByText)
        ]
      },
    ]
  },
  {
    header: 'Опасность',
    children: [
      {
        label: 'От',
        type: FILTER_TYPE_SELECT,
        propName: 'crMin',
        filterHandler: value => item => {
          if (value) {
            if (item.cr) {
              const {exp: valueCrWeight} = crCollection[value]
              const {exp: itemCrWeight} = crCollection[item.cr]

              return itemCrWeight >= valueCrWeight
            }

            return false
          }

          return true
        },
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любой'
          },
          ...crList.map(
            ({ name, id }) => ({ value: id, text: name })
          )
        ]
      },
      {
        label: 'До',
        type: FILTER_TYPE_SELECT,
        propName: 'crMax',
        filterHandler: value => item => {
          if (value) {
            if (item.cr) {
              const {exp: valueCrWeight} = crCollection[value]
              const {exp: itemCrWeight} = crCollection[item.cr]

              return itemCrWeight <= valueCrWeight
            }

            return false
          }

          return true
        },
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любой'
          },
          ...crList.map(
            ({ name, id }) => ({ value: id, text: name })
          )
        ]
      },
    ],
  },
  {
    header: 'Общение',
    children: [
      {
        label: 'Мировоззрение',
        type: FILTER_TYPE_SELECT,
        propName: 'alignmentId',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любое'
          },
          ...aligmentList
            .filter(
              ({ showInList }) => showInList
            )
            .map(
              ({ name, id, children }) => {
                const {nominative: text} = (name[GENDER_MALE] || name[GENDER_ANY])

                return {
                  text: upLetter(text),
                  value: [id, ...children],
                }
              }
            )
            .sort(sortByText)
        ]
      },
      {
        label: 'Язык',
        type: FILTER_TYPE_SELECT,
        propName: 'languageList',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любой'
          },
          ...languageList
            .filter(
              ({ isRealLang }) => isRealLang
            )
            .map(
              ({ name: {nominative: text}, id: value }) => ({ value, text })
            )
            .sort(sortByText)
        ]
      },
    ]
  },
  {
    header: 'Защита',
    children: [
      {
        label: 'Сопротивление к урону',
        type: FILTER_TYPE_SELECT,
        propName: 'resistanceList',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любое'
          },
          ...damageTypeList
            .map(
              ({ fullName, id: value }) => ({ value, text: fullName })
            )
            .sort(sortByText)
        ],
      },
      {
        label: 'Иммунитет к урону',
        type: FILTER_TYPE_SELECT,
        propName: 'immunityList',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любой'
          },
          ...damageTypeList
            .map(
              ({ fullName, id: value }) => ({ value, text: fullName })
            )
            .sort(sortByText)
        ],
      },
      {
        label: 'Иммунитет к состоянию',
        type: FILTER_TYPE_SELECT,
        propName: 'immunityConditionList',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любой'
          },
          ...conditionList
            .map(
              ({ name, id: value }) => ({ value, text: name })
            )
            .sort(sortByText)
        ],
      },
      {
        label: 'Уязвимость к урону',
        type: FILTER_TYPE_SELECT,
        propName: 'vulnerabilityList',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любая'
          },
          ...damageTypeList
            .map(
              ({ fullName, id: value }) => ({ value, text: fullName })
            )
            .sort(sortByText)
        ],
      },
    ],
  },
  {
    header: 'Прочее',
    children: [
      {
        label: 'Особенности',
        type: FILTER_TYPE_SELECT,
        propName: 'abilityIdList',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любые'
          },
          ...creatureAbilityList
            .map(
              ({ fullName: text, id: value }) => ({ value, text })
            )
            .sort(sortByText)
        ],
      },
      {
        label: 'Легендарные действия',
        type: FILTER_TYPE_SELECT,
        propName: 'hasLegendaryPoints',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно'
          },
          {
            value: true,
            text: 'Есть'
          },
          {
            value: false,
            text: 'Нет'
          },
        ],
      },
      {
        label: 'Содержит спойлеры?',
        title: `Является ли НИП важным для сюжета в неком приключении или лоре D&D?`,
        type: FILTER_TYPE_SELECT,
        propName: 'hasSpoilers',
        value: false,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно',
            warning: spoilerWarning,
          },
          {
            value: true,
            text: 'Да',
            warning: spoilerWarning,
          },
          {
            value: false,
            text: 'Нет'
          },
        ],
      },
      {
        label: 'Создаётся заклинанием или способностью',
        type: FILTER_TYPE_SELECT,
        propName: 'isCreatedBySmth',
        value: false,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно'
          },
          {
            value: true,
            text: 'Да'
          },
          {
            value: false,
            text: 'Нет'
          },
        ],
      },
      {
        label: 'Фамильяр',
        title: 'Может быть фамильяром?',
        type: FILTER_TYPE_SELECT,
        propName: 'couldBeFamiliar',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно'
          },
          {
            value: true,
            text: 'Да'
          },
          {
            value: false,
            text: 'Нет'
          },
        ],
      },
      {
        label: 'Переиздано?',
        title: 'Существо было переиздано в другой официальной версии',
        type: FILTER_TYPE_SELECT,
        propName: 'isReReleased',
        value: false,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно',
          },
          {
            value: true,
            text: 'Да',
          },
          {
            value: false,
            text: 'Нет',
          },
        ],
      },
    ],
  },
  {
    label: 'Введите имя существа на русском или английском',
    type: FILTER_TYPE_INPUT,
    propName: SEARCH_PROP_NAME,
    value: ''
  },
];
