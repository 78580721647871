import React from 'react'

import Creature from '@/components/Creature'
import CreatureCatalog from '@/components/CreatureCatalog'

import creatureImageCollection from '@/constants/images/creatureImageCollection'
import {creatureCollection} from "@/constants/creatureList"

import CommonCatalogPageTemplate from '@/templates/CommonCatalogPageTemplate'

const defaultSeoData = {
  title: 'Существа',
  description: 'Каталог всех существ в Dungeons & Dragons',
  img: null,
}

const CreaturePageTemplate = (props) => (
  <CommonCatalogPageTemplate
    defaultSeoData={defaultSeoData}
    itemCollection={creatureCollection}
    ItemComponent={Creature}
    itemImageCollection={creatureImageCollection}
    ListComponent={CreatureCatalog}
    {...props}
  />
)

export default CreaturePageTemplate
