import React from 'react';

import creatureList, { creatureNameList } from '@/constants/creatureList';

import Catalog from '@/components/Catalog';

import generateCreaturePageUrlById from "@/utils/generateCreaturePageUrlById"

import filterListOriginal from './constants/filterList';

const CreatureCatalogContainer = () => (
  <Catalog
    filterListOriginal={filterListOriginal}
    itemList={creatureList}
    itemNameList={creatureNameList} // TODO
    pageTitle='Каталог существ'
    pageUrlGenerator={generateCreaturePageUrlById}
  />
)

export default CreatureCatalogContainer
